<template>
  <v-container fluid>
    <h1>ICRS Hardware Hub</h1>
    <h2>
      <v-icon color="warning">mdi-alert</v-icon> This is an extra hardware hub
      provided by ICRS
    </h2>
    <h3>
      ICRS provide a wide variety of hardware available for IC Hack, if there is
      something that isn't listed please contact a ICRS team on slack, They
      might be able to help.
    </h3>
    <HardwareForm collection="ICRS" />
  </v-container>
</template>

<script>
import HardwareForm from "@/components/HardwareForm";

export default {
  components: { HardwareForm }
};
</script>
